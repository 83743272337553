.ki_page_home {
  &--head .head {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  &--leistungen {
    margin-bottom: 80px;
    .image-text {
      .ap-image-section {

      }
      .link {
        display: block;
        padding: 0;
        .btn {
          width: 100%;
          background: $main-theme;
          @include ki_txt-teaser;
          &:after {
            content: 'MEHR +';
            @include ki_button;
            margin-top: 2em;
          }
          &:hover, &:focus {
            &:after {
              background-color: $ki_color_lightblue;
            }
          }
        }
      }
    }
  }
  &--angebote {
    text-align: center;
    margin-bottom: 20px;
    .link-only {
      display: inline-block;
      margin: 0 1em 1em 0;
      .btn {
        padding: 1em;
        background: transparent;
        color: $main-theme;
        border: 3px solid $main-theme;
        font-size: 1.5rem;
        font-weight: 500;
        @include typo_allcaps;
        &:hover, &:focus {
          background: $main-theme;
          color: #fff;
        }
      }
    }
  }
  &--termine {
    $ki_termine_border: 3px dotted #fff;
    background-color: $ki_color_green !important;
    .headline {
      text-align: center;
      border: none;
      h2 {
        margin-top: 1em;
        margin-bottom: 1em;
        color: #fff;
        border: none;
      }
    }
    a.uncolored {
      &, &:active, &:hover, &:focus {
        color: #fff;
      }
    }
    .list-entries {
      .list-entry {
        //border-bottom: $ki_termine_border;
        @include ki_dotborder(bottom);
        margin-bottom: 20px;
        padding-bottom: 20px;
        .ap-teaser {
          margin-bottom: 0;
        }
        .calendar-sheet-parent {
          //border-right: $ki_termine_border;
          @include ki_dotborder(right);
        }
        .teaser-body {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .fully {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            $ki_termine_column: 25%;
            .teaser-title {
              color: $ki_color_blue;
              font-size: 1.9rem;
              font-weight: 600;
              width: (100 - $ki_termine_column);
            }
            .teaser-date {
              order: -1;
              font-size: 1.6rem;
              //margin-bottom: 0.5em;
              margin-top: 0.25em;
              width: $ki_termine_column;
              &:before {
                content: "\f073";
                font-family: FontAwesome;
                font-style: normal;
                font-size: 1em;
                width: 1em;
                margin-right: 0.5em;
                color: $ki_color_blue;
              }
            }
            .teaser-headline {
              margin-left: $ki_termine_column;
              span {
                color: #fff;
              }
            }
            .teaser-text {
              font-size: 1.6rem;
              margin-left: $ki_termine_column;
              padding-right: 100px;
            }
            // .teaser-title,
            // .teaser-headline,
            // .teaser-text {
            //   // border-left: $ki_termine_border;
            //   @include ki_dotborder(left);
            //   margin-bottom: 3px;
            //   padding-left: 20px;
            // }
          }
          .btn {
            border: 3px solid #fff;
            background-color: transparent;
            font-size: 2em;
            line-height: 1em;
            padding: 0em 0.25em 0.15em;
          }
          &:hover, &:focus {
            .btn {
              border-color: $ki_color_blue;
              background-color: $ki_color_blue;
              color: #fff;
            }
          }
        }
      }
    }
    .list-pagination {
      .btn {
        width: auto;
        margin: 0 auto;
        margin-top: 40px;
      }
    }
  }
  &--aktuelles {
    .headline {
      text-align: center;
      border: none;
      h2 {
        margin-top: 3em;
        margin-bottom: 1em;
        border: none;
      }
    }
    .list-entries {
      .list-entry {
        .teaser-body {
          .fully {
            display: flex;
            flex-direction: column;
            .teaser-date {
              order: 1;
              font-size: 1.5rem;
              margin: 0.75em 0 0.5em;
              font-style: normal;
            }
            .teaser-title {
              order: 2;
              font-size: 1.8rem;
              font-weight: 600;
              color: $ki_color_blue;
              min-height: 0;
            }
            .teaser-headline {
              order: 3;
              font-size: 1.5rem;
            }
            .teaser-text {
              order: 4;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    .list-pagination {
      .btn {
        width: auto;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }
}
