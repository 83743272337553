.ap-blog {
  .teaser-visual {
    margin-bottom: 1rem;
  }
  .teaser-body .fully {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    .teaser-date {
      order: -1;

    }
    .teaser-title {
      color: $ki_color_blue;
      min-height: 0 !important;
    }
  }
}
