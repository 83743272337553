.ap-accordion {
  .panel-group {
    .panel {
      box-shadow: none;
      margin: 8px 0;
      padding-bottom: 8px;
      @include ki_dotborder(bottom, $ki_color_blue);
      .panel-heading .panel-title {
        line-height: 0.75;
        a.accordion-toggle {
          font-weight: 400;
          background: none;
          color: $ki_color_blue;
          display: inline-block;
          width: 100%;
          line-height: 1.2em;
          & > * {
            float: left;
            width: calc(100% - 1.5em - 0.25em - 10px);
          }
          &::after {
            content: " ";
            background: url('../ki/img/accordeon_arrow.svg') no-repeat top center;
            background-size: 1.3em auto;
            float: right;
            border: 2px solid $ki_color_blue;
            color: $ki_color_blue;
            height: 1.5em;
            width: 1.5em;
            padding: 0.25em;
            text-align: center;
            line-height: 0.9em;
            margin-right: 0.5em;
          }
          &:hover, &:focus,
          &:not(.collapsed) {
            font-weight: 600;
            color: $ki_color_blue;
            background: none;
            &::after {
              background-color: $ki_color_blue;
              background-position-y: -1.5em;
            }
          }
        }
      }
      .panel-body {
        border-top: none;
        padding-left: 30px;
        padding-right: 60px;
      }
    }
  }
}
