.ap-event {
  .teaser-body .fully {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    .teaser-date {
      order: -1;
      &:before {
        font-family: FontAwesome;
        content: "\f073";
        font-style: normal;
        font-size: 1em;
        width: 1em;
        margin-right: 0.5em;
      }
    }
    .teaser-title {
      color: $ki_color_blue;
      min-height: 0 !important;
    }
  }
  .teaser-btn {
    @include typo_allcaps;
    margin-top: 15px;
    font-size: 1.2rem;
  }
}
