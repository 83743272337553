.area-foot {
  background-color: transparent;
  padding-top: 40px;
  margin-top: 40px;
  @include ki_gradient(top);
  .container {

  }
  .subfoot {
    background-color: $ki_color_blue;
    color: #fff;
    a:not(.btn) {
      &, &:hover, &:focus {
        color: #fff;
      }
    }
  }
}
