.ki_openingtimes {
  &_wrapper {
    // background-color: $grey-lighter;
  }
  &_header {
    background-color: $grey-lighter;
    margin-bottom: 0;
    padding: 30px 30px 1rem;
    .headline {
      h1, h2, h3, h4 {
        font-size: 1.8rem;
        line-height: 1.25;
        border-bottom: none;
      }
    }
    .ap-teaser {
      margin-bottom: 0 !important;
      padding: 0;
      padding-bottom: 1rem;
      // border-bottom: 1px solid $text-color;
      .fully {
        cursor: default;
        .teaser-title {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.5;
          margin: 0;
        }
        .teaser-date {
          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.5;
          // &::before {
          //   content: ' ( '
          // }
          // &::after {
          //   content: ' )'
          // }
        }
        .teaser-headline,
        .teaser-text {
          display: none;
        }
      }
    }
  }
  &_singlebox {

  }
  background-color: $grey-lighter;
  padding: 30px;
  .ki_openingtimes_header + & {
    padding-top: 0;
  }
  .head {
    * {
      color: $ki_color_blue;
    }
  }
  &_table {
    width: 100%;
    margin-bottom: 0;
    tr {
      border-bottom: 1px solid #dddddf;
      // &:last-of-type {
      //   border-bottom: 1px solid #dddddf;
      // }
    }
    td {
      padding: 1rem 0;
      &:first-of-type {
        padding-left: 0.5rem;
        font-weight: 600;
      }
    }
  }
  &_text {

  }
  .link {
    .btn {
      @include typo_allcaps;
      margin-top: 15px;
    }
  }
}
