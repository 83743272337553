.ki_programm_teaser {
  background-color: $ki_color_blue;
  @each $name, $color in $ki_colors {
    &.#{"" + $name} a.fully {
      background-color: darken($color, 10);
    }
  }
  a.fully {
    display: block;
    padding: 60% 0 40%;
    height: 0;
    overflow: hidden;
    .ap-image {
      display: block;
      transform-origin: top center;
      transform: rotate(-12deg) scale(0.85) translate(10%,-48%);
      box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    }
  }
  .text {
    @include ki_txt-teaser;
    color: #fff;
    padding: 10px 20px 0px;
  }
  .link {
    padding: 10px 20px 20px;
    .btn {
      @include ki_button;
      &::after {
        font-family: "FontAwesome";
        content: "\f019";
        margin-left: 1em;
      }
    }
  }
}
