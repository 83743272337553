.ap-contact {
  .n { // name
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    color: $ki_color_blue;
    [itemprop="givenName"],
    [itemprop="familyName"] {
      // color: $ki_color_blue;
    }
    [itemprop="givenName"] {
      margin-right: 0.3em;
    }
    [itemprop="honorificPrefix"] {
      order: 2;
      font-size: 1.4rem;
      margin-top: 0.15em;
      width: 100%;
      color: $text-dark;
    }
  }
  .ap-icon-label {
    font-weight: 600;
  }
}
