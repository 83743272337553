$ki_bistumheader_height: 1.5em;
$ki_bistumheader_padding: 0.5em;
$ki_bistumheader_totalheight: $ki_bistumheader_height + ($ki_bistumheader_padding * 2);

.ap-header {
  @include ki_gradient(bottom);
  .bis-logogroup {
    margin-bottom: 0;
    .bis-logo {
      .ki_header_logo {
        margin: 0;
        .ap-image-section {
          width: 150px;
          height: auto;
          margin-top: 15px;
        }
      }
    }
  }
}

.ki_header_home {
  .breadcrumbs {
    display: none;
  }
}

@media (min-width: $screen-sm-min) {
.ap-header {
  .mainheader {
    z-index: 500;
    position: relative;
  }
  .bis-top { // Bistumslinks
    background: $main-theme;
    font-size: $nav-meta-font-size;
    padding: $ki_bistumheader_padding 0;
    min-height: $ki_bistumheader_totalheight;
    .ap-linksequence-hf {
      margin: 0;
      padding-right: 15px;
      ul.links li > a{
        color: #fff;
      }
      &:before,
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        height: $ki_bistumheader_totalheight;
        width: 800px;
        background: $main-theme;
        margin-top: - $ki_bistumheader_padding;
      }
      &:before {
        left: 0;
        transform: translateX(-100%);
      }
      &:after {
        right: 0;
        transform: translateX(100%);
      }
    }
  }
  .bis-headgroup {
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
  .bis-logogroup {
    //margin-bottom: 0;
    .bis-logo {
      .ki_header_logo {
        //margin: 0;
        .ap-image-section {
          //width: 150px;
          //height: auto;
          padding: 15px;
          background: #fff;
          margin-top: - $ki_bistumheader_totalheight;
          .image-src-box {
            width: auto;
          }
        }
      }
    }
  }
}
} // sm

@media (max-width: $screen-sm-max) {
  .bis-site {
    display: none;
  }
}

@media (min-width: $screen-md-min) {
  .ap-header {
    .head-navbar {
      border: none;
      margin-top: -5em;
      margin-bottom: 2em;
      padding-bottom: 2px;
      .container {
        padding-left: 165px; // logowidht (150) + padding (15)
      }
      .navbar-nav {
        li {
          a {
            padding-right: 0.6em;
            padding-left: 0.6em;
            background-color: transparent;
            &::after {
              content: none;
            }
          }
          &.active {
            a {
              border: none;
              //font-weight: bold;
            }
          }
          //&:nth-of-type(n-2),
          &:last-of-type {
            a {
              padding-right: 0;
            }
          }
        }
        // #searchButtonHeader {
        //   display: none;
        // }
      }
    }
    .bis-headimage-group {
      .ap-slick-slider {
        margin-bottom: 0;
      }
    }
    .breadcrumbs {
      .breadcrumb {
        float: none !important;
      }
    }
    .bis-logogroup {
      .bis-site {
        margin-top: 15px;
        align-self: flex-start;
        // display: flex;
        // justify-items: flex-end;
        .ap-linksequence-hf {
          ul.links li {
            &>a {
              color: $main-theme;
              font-size: 1.6rem;
              text-transform: none;
            }
            &+li {
              margin-left: 2em;
              &::before {
                content: none;
              }
            }
          }
        }
        // .ki_search_wrapper {
        //   .ki_search_button {
        //     margin-left: 2em;
        //     &.open {
        //       padding: 1em;
        //       & > a.nav-handle {
        //         display: none;
        //       }
        //     }
        //     > .dropdown-menu {
        //       right: 0;
        //       left: auto;
        //       padding: 1em;
        //       min-width: 300px;
        //     }
        //   }
        // }
      }
    }
  }
} // md


@media (min-width: $screen-lg-min) {
  .ap-header {
    .head-navbar {
      .navbar-nav {
        li {
          a {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
} // lg
