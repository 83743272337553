.ap-sitemap {
  .top {
    .nav-toggle {
      margin: $sitemap-ver-padding $sitemap-hor-padding;
      margin-top: 10px;
      padding: 0.5rem 0.25rem;
      border-bottom: 1px solid $grey-lighter;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 1.5rem;
      color: $main-theme;
      &::after {
        color: $grey-lighter;
      }
      &:hover, &:focus {
        &::after {
          color: $main-theme;
        }
      }
    }
    & > .nav-toggle {
      font-size: 1.7rem;
      border-color: $main-theme;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }
    & > ul {
      padding: 0.5rem 0.25rem;
      li {
        a {
          line-height: 1.2em;
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
