.ap-section {
  .text {
    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 0.5em;
        margin-left: 1.25em;
        list-style: none;
        &::before {
          content: "";
          height: 0.5em;
          width: 0.5em;
          background: $ki_color_blue;
          display: inline-block;
          margin-right: 0.75em;
          margin-left: -1.25em;
        }
      }
    }
    a {
      text-decoration: underline;
    }
  }
}

a {
  &.external {
    &:after {
      padding-left: .5em;
    }
  }
}
