$ki_color_blue:       $main-theme;
$ki_color_lightblue:  #0093bd;
$ki_color_green:      #93c01f;
$ki_color_orange:     #f29100;
$ki_color_red:        #e53212;

$ki_colors: (
  blue: $ki_color_blue,
  lightblue: $ki_color_lightblue,
  green: $ki_color_green,
  orange: $ki_color_orange,
  red: $ki_color_red
);

@mixin ki_gradient($position) {
  $ki_color_width: 75px;
  border-#{$position}: 5px solid $ki_color_blue;
  @if($position == top) { border-bottom: 0; border-left: 0; border-right: 0;}
  @else if($position == bottom) { border-top: 0; border-left: 0; border-right: 0;}
  @else if($position == left) { border-top: 0; border-bottom: 0; border-right: 0;}
  @else if($position == right) { border-top: 0; border-bottom: 0; border-left: 0;}
  border-image: repeating-linear-gradient(
    90deg,
    $ki_color_blue,
    $ki_color_blue $ki_color_width, $ki_color_lightblue $ki_color_width,
    $ki_color_lightblue $ki_color_width * 2, $ki_color_green $ki_color_width * 2,
    $ki_color_green $ki_color_width * 3, $ki_color_orange $ki_color_width * 3,
    $ki_color_orange $ki_color_width * 4, $ki_color_red $ki_color_width * 4,
    $ki_color_red $ki_color_width * 5
  ) 1;
}

@mixin ki_dotborder($position, $color:#fff) {
  $ki_dotborder_thickness: 3px;
  $ki_dotborder_padding: 12px;
  $orientation: 0 !default;
  border-#{$position}: $ki_dotborder_thickness solid $color;
  @if($position == top) { border-bottom: 0; border-left: 0; border-right: 0; $orientation: 90 }
  @else if($position == bottom) { border-top: 0; border-left: 0; border-right: 0; $orientation: 90 }
  @else if($position == left) { border-top: 0; border-bottom: 0; border-right: 0; }
  @else if($position == right) { border-top: 0; border-bottom: 0; border-left: 0; }
  border-image: repeating-linear-gradient(
    #{$orientation}deg,
    $color,
    $color $ki_dotborder_thickness, transparent $ki_dotborder_thickness,
    transparent $ki_dotborder_padding + $ki_dotborder_thickness
  ) 1;
}

// Typo

@mixin typo_allcaps {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

@mixin ki_txt-teaser {
  text-align: left;
  word-break: break-word;
  white-space: normal;
  font-size: 1.7rem;
  padding: 1.5em;
  letter-spacing: 0.025em;
}
// Buttons

@mixin ki_button {
  @include typo_allcaps;
  display: block;
  width: fit-content;
  padding: .5em 1.5em;
  border: 2px solid #fff;
  font-size: 0.8em;
}

////////////////////////////////////////////////////////////////////////////////

.ki_space_top {
  margin-top: 4rem;
}
.ki_space_bottom {
  margin-bottom: 4rem;
}

.ap-section {
  .link {
    margin-bottom: 4rem;
  }
}

@import "assets/header";
@import "assets/footer";
@import "assets/page_home";
@import "assets/typography";
@import "assets/_comp-accordion";
@import "assets/_comp-openingtimes";
@import "assets/_comp-programm.scss";
@import "assets/_comp-contact.scss";
@import "assets/_comp-news.scss";
@import "assets/_comp-events.scss";
@import "assets/_comp-form.scss";
@import "assets/_comp-sitemap.scss";
@import "assets/_comp-download.scss"
