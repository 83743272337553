.ap-webform {
  .checkbox i, .icon-append, .icon-prepend, .input input, .radio i, .select select, .textarea textarea, .toggle i {
    border-color: $ki_color_blue;
    border-width: 2px;
  }
  .label {
    color: $ki_color_blue;
  }
  hr {
    @include ki_dotborder(bottom, $ki_color_blue);
  }
  footer {
    border: none;
    padding-top: 0;
    & > section {
      padding-top: 15px;
      @include ki_dotborder(top, $ki_color_blue);
    }
  }
}
